<template>
  <div>
    <a-row>
      <a-col :span="12"
        ><div style="margin-bottom: 20px">
          <a-pagination
            showSizeChanger
            @showSizeChange="onShowSizeChange"
            :pageSizeOptions="pageSizeOption"
            :pageSize.sync="pageSize"
            :total="total"
            v-model="page"
            :showTotal="
              (total) =>
                `Total ${
                  pagination.total == undefined ? '0' : pagination.total
                } items`
            "
            @change="handlePageChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '1000000'"
                >{{ props.value }} / page</span
              >
              <span v-if="props.value === '1000000'">All / page</span>
            </template>
          </a-pagination>
        </div>
      </a-col>
      <a-col :span="12">
        <div style="display: flex">
          <div style="margin-right: 10px">
            <!-- <a-dropdown :trigger="['click']">
              <a-button> <a-icon type="filter" /></a-button>>
              <a-menu slot="overlay">
                <a-form :form="form" @submit="Cariadvancedsearch">
                  <a-form-item label="Role">
                    <a-input v-decorator="['role']" placeholder="Role" />
                  </a-form-item>
                </a-form>
              </a-menu>
            </a-dropdown> -->
            <a-date-picker @change="filter" />
          </div>
          <div style="margin-right: 10px">
            <a-input-search
              placeholder="Input search text"
              @search="onSearch"
              class="customsearch"
              allowClear
            >
              <!-- <a-tooltip
                placement="top"
                title="Advanced Search"
                slot="addonAfter"
              > -->
              <!-- <a-button type="link" @click="AdvancedSearch">
                  <a-icon type="filter" theme="filled" />
                </a-button> -->
              <!-- </a-tooltip> -->
            </a-input-search>
          </div>
          <div>
            <a-tooltip placement="top" title="Download">
              <a-button
                type="primary"
                :disabled="enableds"
                @click="exports"
                :loading="isLoading"
                ><a-icon type="download" /> Export
              </a-button>
            </a-tooltip>
            <!-- <a-tooltip placement="top" title="Settings">
              <a-button type="primary" @click="Settings"
                ><a-icon type="setting" />
              </a-button>
            </a-tooltip> -->
          </div>
        </div>
      </a-col>
    </a-row>
    <a-table
      :rowKey="(record) => record.id"
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="isLoading"
      @change="handleTableChange"
      bordered
    >
      <template slot="checkTitle">
        <a-checkbox v-model="checkeds" @change="isCheckAll" />
      </template>
      <template slot="SelectAll" slot-scope="text, record, index">
        <a-checkbox v-model="model[index]" :value="text" @change="isDeselect" />
      </template>

      <template slot="action" slot-scope="text, record">
        <a-popconfirm
          v-if="data.length"
          title="Serius mau ngapus?"
          @confirm="() => onDelete(record.id)"
        >
          <a href="javascript:;">
            <a-tooltip placement="top" title="Delete">
              <a-button type="danger"><a-icon type="delete" /></a-button>
            </a-tooltip>
          </a>
        </a-popconfirm>

        <a-tooltip placement="top" title="Resend Email">
          <a-button type="primary" @click="Resend(record.id)"
            ><a-icon type="mail"
          /></a-button>
        </a-tooltip>
      </template>

      <!-- <template slot="no" slot-scope="text, record, index">
        {{ index + 1 }}
      </template> -->
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "id",
    width: 45,
    slots: { title: "checkTitle" },
    align: "center",
    scopedSlots: { customRender: "SelectAll" },
  },
  // {
  //   title: "No",
  //   dataIndex: "no",
  //   align: "center",
  //   scopedSlots: { customRender: "no" },
  // },
  {
    title: "Nama Lengkap",
    dataIndex: "fullName",
  },
  {
    title: "E-mail",
    dataIndex: "email",
  },

  {
    title: "No.Telp",
    dataIndex: "phone_number",
  },
  {
    title: "Perusahaan",
    dataIndex: "company",
  },

  {
    title: "Action",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 150,
    align: "center",
  },
];
export default {
  data() {
    return {
      pageSizeOption: ["15", "30", "60", "1000000"],
      data: [],
      pagination: {},
      total: 0,
      page: 1,
      pageSize: 15,
      showTotal: "",
      columns,
      model: [],
      checkAll: [],
      checkeds: false,
      enableds: true,
      showSettng: false,
      paramssearch: {},
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  mounted() {},
  methods: {
    filter(a) {
      this.paramssearch = {
        today: a,
      };

      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        ...this.paramssearch,
      });

      this.$router.push({
        name: "ListReportUser",
        query: {
          page: this.page,
          pageSizes: this.pageSize,
          ...this.paramssearch,
        },
      });
    },
    Settings() {
      this.$store
        .dispatch("Settings", {
          label: "register",
        })
        .then((response) => {
          //alert(response.data.register);
          const checkregist = response.data.register;
          let ini = this;
          this.$confirm({
            title:
              checkregist != null ? "Open Registration" : "Close Registration",
            content:
              checkregist != null
                ? "Are you sure to open user registration ?"
                : "Are you sure to close user registration ?",
            onOk() {
              //console.log("OK");
              let vars = null;
              if (checkregist != null) {
                vars = null;
              } else {
                vars = "1";
              }

              ini.$store
                .dispatch("OpenCloseRegistration", {
                  val: vars,
                })
                .then((response) => {
                  ini.$notification.success({
                    message: response.data.message,
                  });
                });

              //
            },
            onCancel() {
              console.log("Cancel");
            },
            class: "test",
          });
        });
    },
    Resend(id) {
      this.$store
        .dispatch("ResendEmail", {
          id: id,
        })
        .then((response) => {
          this.$notification.success({
            message: response.data.message,
          });
        });
    },
    onDelete(id) {
      this.$store
        .dispatch("DeleteUser", {
          id: id,
        })
        .then((response) => {
          this.$notification.success({
            message: response.data.message,
          });
          this.fetch({
            page: this.page,
            pageSizes: this.pageSize,
          });
        });
    },
    isDeselect(e) {
      this.checkeds = false;
      if (this.model == []) {
        this.enableds = true;
      } else {
        if (this.model.filter((keys) => keys == true).length == 0) {
          this.enableds = true;
        } else {
          this.enableds = false;
        }
      }
    },
    isCheckAll(e) {
      Object.assign(this, {
        model: e.target.checked ? this.data.map((i) => true) : [],
      });

      this.enableds = e.target.checked ? false : true;
    },

    fetch(params = {}) {
      this.$store
        .dispatch("GetListRegisterUser", {
          ...params,
        })
        .then((response) => {
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          pagination.pageSize = response.data.pageSize;
          pagination.page = response.data.page;

          this.total = pagination.total;
          this.data = response.data.data;
          this.pagination = pagination;

          this.model = [];
          this.checkeds = false;
        });
    },

    exports() {
      const check = this.model.map((i, index) =>
        i == true ? this.data[index].id : ""
      );

      this.$store.dispatch("ExportRegisterUser", {
        data: check.filter((keys) => keys !== ""),
      });
      console.log(check.filter((keys) => keys !== ""));
    },

    handleTableChange(page, filters, sorter) {
      const params = this.paramssearch;
      const pager = { ...this.pagination };
      pager.current = this.page;
      //console.log("ini", pager);
      // this.page = pager.page
      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        sortField: sorter.field,
        sortOrder: sorter.order,
        search: this.pencarian,
        ...params,
      });
      //alert(pager.pageSize)
    },

    handlePageChange(page) {
      //checklist reset
      const params = this.paramssearch;
      const pager = { ...this.pagination };
      pager.current = page;

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        ...params,
      });

      this.$router.push({
        name: "ListReportUser",
        query: {
          page: pager.current,
          pageSizes: pager.pageSize,
          ...params,
        },
      });
    },

    onShowSizeChange(current, pageSize) {
      const params = this.paramssearch;
      this.page = current;
      this.pageSize = pageSize;
      //console.log(this.pageSize)
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        ...params,
      });
      this.handlePageChange(current);

      this.$router.push({
        name: "ListReportUser",
        query: {
          page: this.page,
          pageSizes: this.pageSize,
          ...params,
        },
      });
    },

    onSearch(value) {
      this.search = value;
      this.pencarian = value;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        search: this.search,
      });
    },
  },
};
</script>
